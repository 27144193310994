import * as React from 'react'
import { Box, CheckboxField, EditPage, EntityAccessor, Field, HasMany, HasOne, Heading, LinkButton, MultiSelectField, PersistButton, RadioField, Repeater, Stack, TextareaField } from '@contember/admin'
import { OrganizationSideForm } from '../../../components/forms/OrganizationForm'

export default () => (
	<>
		<EditPage entity="Organization(id=$id)" rendererProps={{ title: 'Upravit školu', side: <OrganizationSideForm />, actions: '' }}>
			<div className="bg-white p-4 rounded">
				<div className="flex justify-between">
					<h2>Škola</h2>
					<div><LinkButton to="admin/organization/edit(id: $entity.id)">Upravit tuto školu</LinkButton></div>
				</div>
				<table className="max-w-2xl">
					<tr>
						<td>Jméno školy</td>
						<td className="pl-8 font-bold text-black"><Field field="name" /></td>
					</tr>
					<tr>
						<td>IČ</td>
						<td className="pl-8 font-bold text-black"><Field field="idNo" /></td>
					</tr>
					<tr>
						<td>Ulice a č.p.</td>
						<td className="pl-8 font-bold text-black"><Field field="street" /></td>
					</tr>
					<tr>
						<td>Město</td>
						<td className="pl-8 font-bold text-black"><Field field="city" /></td>
					</tr>
					<tr>
						<td>PSČ</td>
						<td className="pl-8 font-bold text-black"><Field field="postalCode" /></td>
					</tr>
					<tr>
						<td>Kraj</td>
						<td className="pl-8 font-bold text-black"><Field field="region" /></td>
					</tr>
					<tr>
						<td>Počet nahlášených týmů</td>
						<td className="pl-8 font-bold text-black"><Field field="teamCounts" /></td>
					</tr>
				</table>
				<hr />
				<CheckboxField field="isLocationPublic" label="Zobrazovat na veřejné mapě" />
				<div className="flex justify-between gap-2">
					<HasMany field="addresses">
						<div className="mt-4 shadow p-2 w-full">
							<table className="max-w-2xl">
								<tr>
									<td className="font-bold text-black"><Field field="type" /></td>
									<td></td>
								</tr>
								<tr>
									<td>IČ</td>
									<td className="pl-8 font-bold text-black"><Field field="idNo" /></td>
								</tr>
								<tr>
									<td>Ulice a č.p.</td>
									<td className="pl-8 font-bold text-black"><Field field="street" /></td>
								</tr>
								<tr>
									<td>Město</td>
									<td className="pl-8 font-bold text-black"><Field field="city" /></td>
								</tr>
								<tr>
									<td>PSČ</td>
									<td className="pl-8 font-bold text-black"><Field field="postalCode" /></td>
								</tr>
							</table>
						</div>
					</HasMany>
				</div>
			</div>
			<Box heading={<Stack direction="horizontal" align="center" justify="stretch">
				<Heading depth={5}>Správci</Heading>
				<LinkButton to="admin/organization/createUser(organizationId: $entity.id)">Přidat nového</LinkButton>
			</Stack>}>
				<MultiSelectField
					field="users[role=organizationManager]"
					label="Správci"
					options="User[role=organizationManager]"
					renderOption={(accessor: EntityAccessor) => {
						const lastName = accessor.getField('lastName').value
						const firstName = accessor.getField('firstName').value
						const email = accessor.getField('email').value

						return `${lastName} ${firstName} (${email})`
					}}
					optionsStaticRender={
						<>
							<Field field="lastName" />
							<Field field="firstName" />
							<Field field="email" />
						</>
					}
				/>
				<MultiSelectField
					field="users[role=teamManager]"
					label="Učitelé"
					options="User[role=teamManager]"
					renderOption={(accessor: EntityAccessor) => {
						const lastName = accessor.getField('lastName').value
						const firstName = accessor.getField('firstName').value
						const email = accessor.getField('email').value

						return `${lastName} ${firstName} (${email})`
					}}
					optionsStaticRender={
						<>
							<Field field="lastName" />
							<Field field="firstName" />
							<Field field="email" />
						</>
					}
				/>
			</Box>
			<Box heading="Poznámky">
				<Repeater label={undefined} field="notes" sortableBy="displayOrder">
					<TextareaField field="text" label={undefined} />
				</Repeater>
				<PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />
			</Box>
			<Box heading="Dotazník">
				<HasOne field="questionnaire">
					<CheckboxField field="wantToGetInvolved" label="Chceme se zapojit do soutěže" />
					<hr />
					<RadioField
						field="howWillYouParticipate"
						label="Jakým způsobem chcete zaznamenávat cesty do školy?"
						options={[
							{ value: 'digital', label: 'Digitálně v aplikaci' },
							{
								value: 'analogPrintSelf',
								label: 'Na papír (vystiskneme si sami)',
							},
							{
								value: 'analogByPost',
								label: 'Objednat originální papírovou motivační sadu (500,-, objednat do 15. 6.)',
							},
						]}
						defaultValue={true}
					/>
					<hr />
					<RadioField
						field="howDidYouFindOutUs"
						label="Jak jste se o výzvě Pěšky do školy dozvěděli?*"
						options={[
							{ value: 'involvedRegularly', label: 'Účastníme se pravidelně' },
							{ value: 'direct', label: 'Oslovili nás přímo z Pěšky městem' },
							{ value: 'parents', label: 'S iniciativou přisli rodiče' },
							{ value: 'city', label: 'Od obce nebo městské části' },
							{ value: 'socialNetworks', label: 'Ze sociálních sítí' },
							{ value: 'media', label: 'Z médií' },
						]}
					/>
				</HasOne>
			</Box>

			<Box heading="Týmy">
				<HasMany field="teams">
					<Field field="name" />
					<br />
				</HasMany>
			</Box>

			<PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" />
		</EditPage>
	</>
)
