
import { Component, EntityListSubTree, Field, FieldView, HasOne, LinkButton, useEntityListSubTree } from '@contember/admin'
import * as React from 'react'

import defaultView from '../assets/images/default-view-bg.png'
import { imageResizingUrl } from '../utils/images'

type OrganizationListProps = {
	page?: number
	region?: string
}
const itemsPerPage = 24

export const OrganizationList = Component(({ page = 1, region }: OrganizationListProps) => {
	const allOrganizations = Array.from(useEntityListSubTree('allOrganizations')).length
	const pages = Math.ceil(allOrganizations / itemsPerPage)

	return (<>
		<div className="organizations-list">
			<EntityListSubTree entities={region ? `Organization[region=${region} && isLocationPublic=true && stats.isActiveInCurrentEdition = true]` : `Organization[isLocationPublic=true && stats.isActiveInCurrentEdition = true]`} limit={(itemsPerPage / 2)} offset={(page - 1) * itemsPerPage} orderBy="stats.teamsTotalPoints desc">
				<Article />
			</EntityListSubTree>
		</div>
		{/*
		<div className="my-5">
			<a href="https://skoly.holubova.cz/cs/pravni-pomoc-pro-skoly/">
				<img src={hk_banner} alt="hk_banner" width="1280" height="100" loading="lazy" className="max-w-full h-auto" />
			</a>
		</div>
		*/}
		<div className="organizations-list">
			<EntityListSubTree entities={region ? `Organization[region=${region} && isLocationPublic=true && stats.isActiveInCurrentEdition = true]` : `Organization[isLocationPublic=true && stats.isActiveInCurrentEdition = true]`} limit={itemsPerPage - (itemsPerPage / 2)} offset={(itemsPerPage / 2) + ((page - 1) * itemsPerPage)} orderBy="stats.teamsTotalPoints desc">
				<Article />
			</EntityListSubTree>
		</div>

		{pages > 1 && <div className="mt-4 flex justify-center gap-2">
			<LinkButton distinction="primary" to={{ pageName: 'view/list', parameters: { page: page - 1, ...(region && { region: region }) } }} disabled={page === 1}>Předchozí strana</LinkButton>
			<LinkButton distinction="primary" to={{ pageName: 'view/list', parameters: { page: page + 1, ...(region && { region: region }) } }} disabled={pages == page}>Následující strana</LinkButton>
		</div >}

	</>)

}, ({ page = 1, region }: OrganizationListProps) => (<>
	<EntityListSubTree alias="allOrganizations" entities={region ? `Organization[region=${region} && isLocationPublic=true && stats.isActiveInCurrentEdition = true]` : `Organization[isLocationPublic=true && stats.isActiveInCurrentEdition = true]`} />
	<EntityListSubTree alias="organizationsAboveBanner" entities={region ? `Organization[region=${region} && isLocationPublic=true && stats.isActiveInCurrentEdition = true]` : `Organization[isLocationPublic=true && stats.isActiveInCurrentEdition = true]`} limit={(itemsPerPage / 2)} offset={(page - 1) * itemsPerPage} orderBy="stats.teamsTotalPoints desc">
		<Article />
	</EntityListSubTree>
	<EntityListSubTree entities={region ? `Organization[region=${region} && isLocationPublic=true && stats.isActiveInCurrentEdition = true]` : `Organization[isLocationPublic=true && stats.isActiveInCurrentEdition = true]`} limit={itemsPerPage - (itemsPerPage / 2)} offset={(itemsPerPage / 2) + ((page - 1) * itemsPerPage)} orderBy="stats.teamsTotalPoints desc">
		<Article />
	</EntityListSubTree>
</>), 'OrganizationList')

const Article = Component(() => {
	return (
		<article className="organizations-list-item">
			<HasOne field="coverImage">
				<FieldView
					fields={['url', 'width', 'height', 'alt']}
					render={(url, width, height, alt) => {
						const urlValue = url.getAccessor().value

						return (
							<header>
								{urlValue ? (
									<img
										src={imageResizingUrl(String(urlValue))}
										alt={
											alt.getAccessor().value
												? String(alt.getAccessor().value)
												: ''
										}
										width={
											width.getAccessor().value
												? String(width.getAccessor().value)
												: undefined
										}
										height={
											height.getAccessor().value
												? String(height.getAccessor().value)
												: undefined
										}
									/>
								) : (
									<img src={defaultView} className="placeholder" alt="" />
								)}
							</header>
						)
					}}
				/>
			</HasOne>
			<section>
				<h3>
					<Field field="name" />
				</h3>
				<dl>
					<dt>
						Počet týmů
					</dt>

					<dd>
						<HasOne field="stats">
							<Field field="teamsApproved" />
						</HasOne>
					</dd>
				</dl>
				<dl>
					<dt>
						Počet bodů
					</dt>

					<dd>
						<HasOne field="stats">
							<FieldView field="teamsTotalPoints" render={({ value }) => {
								const currentDate = new Date()
								const monday18September8AM = new Date('September 18, 2023 08:00:00')
								if (currentDate < monday18September8AM) {
									return <>0</>
								}
								return <>{value ? (Math.round(Number(value))) : 0}</>
							}} />
						</HasOne>
					</dd>
				</dl>
			</section>
			<footer>
				<LinkButton to="view/organization(id: $entity.id)" elevation="none">
					Detail školy &rarr;
				</LinkButton>
			</footer>
		</article>
	)
}, () => (
	<>
		<Field field="id" />
		<HasOne field="coverImage">
			<Field field="url" />
			<Field field="width" />
			<Field field="height" />
			<Field field="alt" />
		</HasOne>
		<Field field="name" />
		<HasOne field="stats">
			<Field field="teamsApproved" />
			<Field field="teamsTotalPoints" />
		</HasOne>
	</>
), 'Article')

