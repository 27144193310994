import * as React from 'react'
import { EditPage, LinkButton, PersistButton } from '@contember/admin'
import { TeamFormOrganization } from '../../../components/forms/TeamFormOrganization'
import { DeleteTeam } from '../../../components/buttons/DeleteTeam'

export default () => (
	<>
		<EditPage
			entity="Team(id=$id)"
			rendererProps={{
				title: 'Upravit třídu',
				actions: <>
					<div className="mr-4"><DeleteTeam redirectToOnSuccess="team/list" /></div>
					<PersistButton labelSave="Uložit" labelSaved="Uložit" />
					<LinkButton to="organization/team/activity(id: $entity.id)">Zadat doprovodnou aktivitu</LinkButton>
				</>,
			}}
		>
			<div className="form-box">
				<TeamFormOrganization />
			</div>
		</EditPage>
	</>
)
