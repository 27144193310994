import * as React from 'react'
import {
	Component,
	Field,
	RadioField,
	StaticRender,
	TextField,
} from '@contember/admin'
import { userRoles } from '../../modules/userRoles'

export const UserFormOrganizationManagerEdit = Component(() => (
	<>
		<TextField field="firstName" label="Jméno" required />
		<TextField field="lastName" label="Příjmení" required />
		<TextField field="phone" label="Kontaktní telefon" required />
		<TextField field="email" label="Kontaktní e-mail" required disabled />

		<RadioField
			field={'role'}
			label={'Role'}
			options={userRoles.filter(item => item.value !== 'admin')}
		/>

		<StaticRender>
			<Field field="personId" />
			<Field field="identityId" />
		</StaticRender>
	</>
))
