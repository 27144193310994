import {
	Component,
	CreatePage,
	EntityAccessor,
	EntityListSubTree,
	EntitySubTree,
	Field,
	HasOne,
	HiddenField,
	If,
	PersistButton,
	useEntity,
	useEntitySubTree,
	useIdentity,
} from '@contember/admin'
import * as React from 'react'
import {
	TeamFormTeamManager,
} from '../../components/forms/TeamFormTeamManager'
import { OnlyIfIsCurrentEditionOpen } from '../../components/OnlyIfIsCurrentEditionOpen'
import { TeamTitleBar } from '../../components/partials/TeamTitleBar'
import { IsTeamOrClass } from '../../components/IsTeamOrClass'

export default () => {
	const identity = useIdentity()
	return (
		<>
			<TeamTitleBar />
			<CreatePage
				entity="Team"
				rendererProps={{
					title: <IsTeamOrClass teamOutput="Týmy byly zrušeny" classOutput="Přidat třídu" personId={identity?.person?.id} />,
					actions: <></>,
				}}
				redirectOnSuccess="team/editTeam(id: $entity.id)"
			>
				<div className="form-box">
					<OnlyIfIsCurrentEditionOpen closed={
						<h1>
							<IsTeamOrClass teamOutput="Týmy již nelze přidávat (registrace byly uzavřeny)" classOutput="Třídy již nelze přidávat (registrace byly uzavřeny)" personId={identity?.person?.id} />
						</h1>}>
						<IsTeamOrClassType personId={identity?.person?.id} />
						<If condition="[type=team]">
							<p>Týmy již v tomto ročníku přidávat nelze. Lze vkládat pouze školní třídy.</p>
						</If>
						<If condition="[type=class]">
							<TeamFormTeamManager />
							<PersistButton labelSave="Vytvořit třídu" labelSaved="Vytvořit třídu" />

							<EntitySubTree
								entity={`Organization(users.personId='${identity?.person?.id}')`}
								alias="organizationToConnect"
							/>
							<EntitySubTree
								entity={`User(personId='${identity?.person?.id}')`}
								alias="userToConnect"
							/>
							<EntityListSubTree
								entities={`Edition`}
								alias="editionsToConnect"
								orderBy="startedAt desc"
							>
								<HiddenField field="name" />
							</EntityListSubTree>
							<HasOne
								field="organization"
								onInitialize={(getAccessor, options) => {
									const organizationToConnect = options.getEntitySubTree(
										`organizationToConnect`,
									)
									const parentEntity = getAccessor().getParent()
									if (parentEntity && organizationToConnect) {
										(parentEntity as EntityAccessor).connectEntityAtField(
											'organization',
											organizationToConnect,
										)
									}
								}}
							/>
							<HasOne
								field="manager"
								onInitialize={(getAccessor, options) => {
									const userToConnect = options.getEntitySubTree(`userToConnect`)
									const parentEntity = getAccessor().getParent()
									if (parentEntity && userToConnect) {
										(parentEntity as EntityAccessor).connectEntityAtField(
											'manager',
											userToConnect,
										)
									}
								}}
							/>
							<HasOne
								field="edition"
								onInitialize={(getAccessor, options) => {
									const editionsToConnect = Array.from(
										options.getEntityListSubTree(`editionsToConnect`),
									)
									const parentEntity = getAccessor().getParent()
									if (editionsToConnect.length && parentEntity) {
										(parentEntity as EntityAccessor).connectEntityAtField(
											'edition',
											editionsToConnect[0],
										)
									}
								}}
							/>
						</If>

					</OnlyIfIsCurrentEditionOpen>
				</div>
			</CreatePage >
		</>
	)
}

type IsTeamOrClassTypeProps = {
	personId?: string
}

const IsTeamOrClassType = Component(() => {
	const entity = useEntity()
	const organizationToConnect = useEntitySubTree('organizationToConnect')
	if (organizationToConnect.idOnServer) {
		entity.getField('type').updateValue('class')
	} else {
		entity.getField('type').updateValue('team')
	}
	return <></>
}, ({ personId }: IsTeamOrClassTypeProps) => (
	<>
		<Field field="type" />
		<EntitySubTree
			entity={`Organization(users.personId='${personId}')`}
			alias="organizationToConnect"
		/>
	</>), 'IsTeamOrClassType')
