import * as React from 'react'
import { CoalesceTextCell, DataGrid, EditPage, Field, FieldContainer, FieldView, FloatField, HasMany, LinkButton, NumberField, PersistButton, Repeater, Stack, TextCell } from '@contember/admin'
import { format, parseISO } from 'date-fns'

export default () => (
	<>
		<EditPage entity="Team(id=$id)" rendererProps={{ title: <>Doprovodné aktivity: <Field field="name" /></>, actions: <><LinkButton to="admin/team/activitiesList">Zpět na doprovodné aktivity</LinkButton></> }}>
			<div className="flex space-x-4">
				<FloatField field="accompanyingActivityPoints" label="Počet bodů za doprovodné aktivity" />
				<div className="mt-5"><PersistButton isPrimary labelSave="Uložit" labelSaved="Uložit" /></div>
			</div>
			<HasMany field="accompanyingActivities">
				<div className="bg-white bg-opacity-40 rounded-xl p-4">
					<p><Field field="name" /></p>
					<HasMany field="attachments">
						<FieldView field="image.url" render={({ value }) => {
							if (value) {
								const stringValue = String(value)
								if (stringValue.endsWith('.jpg') || stringValue.endsWith('.jpeg') || stringValue.endsWith('.png') || stringValue.endsWith('.gif')) {
									return <img src={stringValue} alt="attachment" className="max-w-auto w-full" />
								} else {
									return <a target="_blank" href={stringValue}>Otevřít soubor</a>
								}
							}
						}} />
						<FieldView field="file.url" render={({ value }) => {
							if (value) {
								const stringValue = String(value)
								if (stringValue.endsWith('.jpg') || stringValue.endsWith('.jpeg') || stringValue.endsWith('.png') || stringValue.endsWith('.gif')) {
									return <img src={stringValue} alt="attachment" className="max-w-auto w-full" />
								} else {
									return <a target="_blank" href={stringValue}>Otevřít soubor</a>
								}
							}
						}} />
					</HasMany>
				</div>
			</HasMany>
		</EditPage>
	</>
)
