import { useProjectSlug, useShowToast, EntityAccessor, useRedirect } from '@contember/admin'
import { useCallback } from 'react'

import { useInvite } from './useInvite'
import { useUpdateContentUser } from './useUpdateContentUser'

export const useInviteUser = () => {
	const invite = useInvite()
	const project = useProjectSlug()
	const toast = useShowToast()
	const updateContentUser = useUpdateContentUser()
	const redirect = useRedirect()

	return useCallback(async (getAccessor: () => EntityAccessor) => {
		const accessor = getAccessor()
		const personId = accessor.getField('personId')
		const identityId = accessor.getField('identityId')
		const role = accessor.getField<string>('role').value
		const organization = accessor.getEntity('organization')
		if (personId.value || !role || !project) {
			return
		}

		const result = await invite({
			email: String(accessor.getField('email').value),
			projectSlug: project,
			memberships: [{ role, variables: [] }],
			method: 'RESET_PASSWORD',
			mailVariant: role === 'teamManager' ? 'teacher' : 'director',
		})

		if (!result.ok) {
			toast({
				message: `Nepodařilo se upravit uživatele: ${result.error.developerMessage}`,
				dismiss: true,
				type: 'error',
			})
			return
		}

		let state = undefined
		if (!result.result.isNew && organization.idOnServer) {
			const { updateUser } = await updateContentUser({
				by: { personId: result.result.person.id },
				data: {
					organization: { connect: { id: organization.idOnServer } }
				}
			})
			state = updateUser

		}

		return () => {
			if (result.result.isNew) {
				personId.updateValue(result.result.person.id)
				identityId.updateValue(result.result.person.identity.id)
			} else {
				if (state?.ok) {
					toast({
						message: `Uživatel s tímto e-mailem již má založen účet z minula a byl přiřazen k vaší organizaci.`,
						dismiss: true,
						type: 'success',
					})
					accessor.addError(undefined as any)
					redirect('organization/user/list')
					return
				} else {
					accessor.addError('Uživatel s tímto e-mailem je již registrován v rámci jiné školy.')
					return
				}
			}
		}
	}, [])
}
