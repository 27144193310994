import { Component, DataBindingProvider, EntitySubTree, FeedbackRenderer, Field, GenericPage, HasMany, HasOne, useEntitySubTree, useIdentity, useRedirect } from '@contember/admin'
import * as React from 'react'

export default () => {

	const identity = useIdentity()
	return (
		<GenericPage>
			<DataBindingProvider stateComponent={FeedbackRenderer}>
				<TeamDashboardRedirect personId={identity.person?.id} />
			</DataBindingProvider>
		</GenericPage>
	)
}
type TeamDashboardRedirectProps = {
	personId: string | undefined
}

const TeamDashboardRedirect = Component<TeamDashboardRedirectProps>(() => {
	const userInfo = useEntitySubTree('userInfo')
	const redirect = useRedirect()
	if (!userInfo.getEntity('organization').idOnServer && Array.from(userInfo.getEntityList('teams')).length === 1) {
		window.location.href = `/team/view-team?id=${Array.from(userInfo.getEntityList('teams'))[0].idOnServer}`
		//redirect(`team/editTeam`, { id: Array.from(userInfo.getEntityList('teams'))[0].idOnServer }), [redirect]
	} else {
		redirect('team/list'), [redirect]
	}
	return null
}, ({ personId }) => (
	<EntitySubTree entity={`User(personId='${personId}')`} alias="userInfo">
		<HasOne field="organization"><Field field="name" /></HasOne>
		<HasMany field="teams" />
	</EntitySubTree>
), 'TeamDashboardRedirect')
