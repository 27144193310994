import * as React from 'react'
import { Button, Component, TextareaInput, useEntity, usePersist, usePersistWithFeedback } from '@contember/admin'

type BulkAppendTeamMembersProps = {
	isBulkAppendActive: boolean
	setIsBulkAppendActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const BulkAppendTeamMembers = Component<BulkAppendTeamMembersProps>(
	({ setIsBulkAppendActive }) => {
		const entity = useEntity()
		const persist = usePersistWithFeedback()
		const [bulkAppend, setBulkAppend] = React.useState('')


		return (
			<div className={`bg-white  relative flex flex-col z-10 ${!entity.idOnServer ? '-mb-20 pb-4' : ''}`}>
				<div className="w-full [&>textarea]:rounded-lg [&>textarea]:w-full">
					<TextareaInput value={bulkAppend} onChange={value => setBulkAppend(value ?? '')} minRows={20} />
				</div>
				<p>Zadejte seznam žáků. Nový řádek = nový žák. Seznam žáků můžete hromadně nahrát např. z Excelu.</p>
				<Button distinction="primary" onClick={() => {
					!entity.idOnServer && entity.getEntityList('teamMembers').getAccessor().disconnectAll()
					const lastMember = Array.from(entity.getEntityList('teamMembers').getAccessor()).pop()
					let lastMemberOrder = lastMember?.getAccessor().getField<number>('order').value ?? 0
					bulkAppend.split('\n').forEach(teamMemberItem => {
						entity.getEntityList('teamMembers').getAccessor().createNewEntity(getAccessor => {
							const teamMember = getAccessor()
							teamMember.getField<string>('name').updateValue(teamMemberItem)
							teamMember.getField<number>('order').updateValue(lastMemberOrder)
						})
					})
					!!entity.idOnServer && persist()
					setIsBulkAppendActive(false)
					setBulkAppend('')
				}}>Přidat žáky</Button>
			</div>

		)
	},
	() => (
		<></>
	),
	'BulkAppendTeamMembers',
)
