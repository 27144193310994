import {
	Component,
	EntityId,
	EntityListAccessor,
	Field,
	FieldContainer,
	HasMany,
	HasOne,
	Select,
	useEntity,
	usePersistWithFeedback,
} from '@contember/admin'
import { format, formatISO, isBefore, isSameDay, parseISO, startOfDay } from 'date-fns'
import * as React from 'react'

type AttendanceFormTeamMemberProps = {
	datesOfCurrentWeek: Date[]
	attendanceTypes: EntityListAccessor
	dateFormat: string
	fillType?: 'select' | 'pictogram'
	attendanceNonCompetitive?: boolean
}

export const AttendanceFormTeamMember = Component(
	({
		datesOfCurrentWeek,
		attendanceTypes,
		dateFormat,
		fillType = 'select',
		attendanceNonCompetitive,
	}: AttendanceFormTeamMemberProps) => {
		const entity = useEntity()
		const attendances = entity.getEntityList('attendances')
		const triggerPersist = usePersistWithFeedback()

		datesOfCurrentWeek.forEach(date => {
			if (
				!Array.from(attendances).find(item =>
					isSameDay(
						parseISO(item.getField<string>('date').value ?? ''),
						date,
					),
				)
			) {
				attendances.createNewEntity(entityAccessor => {
					const entity = entityAccessor()
					entity.getField('date').updateValue(format(date, dateFormat))
					entity.getField('wasPresent').updateValue(null)
					entity.getField('isCompetitive').updateValue(attendanceNonCompetitive === true ? false : true)
					entity.disconnectEntityAtField('attendanceType')
				})
			}
		})
		return (
			<tr>
				<td className="name-cell font-bold text-xl">
					<Field field="name" />
				</td>
				{datesOfCurrentWeek.map(date => {
					const attendance = Array.from(attendances).find(item =>
						isSameDay(
							parseISO(item.getField<string>('date').value ?? ''),
							date,
						),
					)
					if (!attendance) {
						return <td></td>
					}
					let value: EntityId | undefined = undefined

					if (attendance.getEntity('attendanceType').idOnServer) {
						value = attendance.getEntity('attendanceType').idOnServer
					}

					return (
						<td className="date-cell" key={formatISO(date)}>
							<FieldContainer label={undefined} className="attendanceFormLabel">
								{fillType === 'pictogram' ? (<div className="flex gap-2">
									{...Array.from(attendanceTypes).map(type => {
										const icon = type.getEntity('icon')
										return <button
											className="inline-flex border-0 flex-grow-0 flex-shrink-0 m-0 p-2 bg-transparent rounded-full overflow-hidden"
											onClick={() => {
												if (attendance) {
													if (type) {
														const points = type.getField<number>('points').value
														if (points && points < 0) {
															// attendance.disconnectEntityAtField('attendanceType')
															attendance.connectEntityAtField('attendanceType', type)
															attendance.getField('wasPresent').updateValue(false)
															attendance.getField('isCompetitive').updateValue(attendanceNonCompetitive === true ? false : true)
														} else {
															attendance.connectEntityAtField('attendanceType', type)
															attendance.getField('wasPresent').updateValue(true)
															attendance.getField('isCompetitive').updateValue(attendanceNonCompetitive === true ? false : true)

														}
													}
													triggerPersist().catch(() => { })
												}
											}}
											disabled={isBefore(startOfDay(new Date()), date)}
										>
											<img
												src={icon.getField<string>('url').value ?? ''}
												className={`icon w-[60px] h-auto rounded-full hover:opacity-100 ${value === type.idOnServer ? 'opacity-100 outline outline-offset-2 outline-4 outline-blue-400' : 'opacity-30'}`}
												height={icon.getField<string>('height').value ?? ''}
												width={icon.getField<string>('width').value ?? ''}
												alt=""
											/>
										</button>
									})}</div>) : (
									<Select
										options={[
											...Array.from(attendanceTypes).map(type => {
												const icon = type.getEntity('icon')
												return {
													value: String(type.idOnServer ?? ''),
													label: (
														<div className="itemWrapper flex items-center">
															{icon.idOnServer && (
																<img
																	src={icon.getField<string>('url').value ?? ''}
																	className="icon mr-2 max-w-[40px] h-auto rounded-full"
																	height={icon.getField<string>('height').value ?? ''}
																	width={icon.getField<string>('width').value ?? ''}
																	alt=""
																/>
															)}
															<span className="text">{type.getField<string>('name').value ?? ''}</span>
														</div>
													) as unknown as string,
												}
											}),
										]}
										onChange={value => {
											if (attendance) {
												if (!value) {
													attendance.disconnectEntityAtField('attendanceType')
													attendance.getField('wasPresent').updateValue(true)
												}
												const attendanceType = Array.from(attendanceTypes).find(
													type => type.idOnServer === value,
												)
												if (attendanceType) {
													const points = attendanceType.getField<number>('points').value
													if (points && points < 0) {
														// attendance.disconnectEntityAtField('attendanceType')
														attendance.connectEntityAtField('attendanceType', attendanceType)
														attendance.getField('wasPresent').updateValue(false)
														attendance.getField('isCompetitive').updateValue(attendanceNonCompetitive === true ? false : true)
													} else {
														attendance.connectEntityAtField('attendanceType', attendanceType)
														attendance.getField('wasPresent').updateValue(true)
														attendance.getField('isCompetitive').updateValue(attendanceNonCompetitive === true ? false : true)

													}
												}
												triggerPersist()
											}
										}}
										disabled={isBefore(startOfDay(new Date()), date)}
										value={value}
									/>
								)}
							</FieldContainer>
						</td>
					)
				})}
				{!attendanceNonCompetitive && <td className="total-points-cell">
					<HasOne field="stats">
						<Field field="totalPoints" />
					</HasOne>
				</td>}

			</tr >
		)
	},
	() => (
		<>
			<HasMany field="attendances">
				<Field field="date" />
				<Field field="wasPresent" />
				<HasOne field="attendanceType" />
				<Field field="createdAt" />
				<Field field="isCompetitive" />
			</HasMany>
		</>
	),
	'AttendanceFormTeamMember',
)
