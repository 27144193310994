import { useProjectSlug } from '@contember/admin'
import { useCallback } from 'react'
import { useCreateUser } from './useCreateUser'

import { useInvite } from './useInvite'
import { useUpdateContentUser } from './useUpdateContentUser'

export const useInviteMultipleTeamManager = () => {
	const invite = useInvite()
	const createUser = useCreateUser()
	const updateContentUser = useUpdateContentUser()
	const project = useProjectSlug()
	const returnMessages: Array<string> = []
	return useCallback(
		async (emails: string, organizationId: string | undefined) => {
			if (!organizationId || !project) {
				return ['Uživatele nelze pozvat, není dostupné ID školy']
			}

			for (const email of emails.split('\n')) {
				const trimmedEmail = email.trim()
				if (email) {
					const inviteResponse = await invite({
						projectSlug: project,
						email,
						memberships: [{ role: 'teamManager', variables: [] }],
						method: 'RESET_PASSWORD',
						mailVariant: 'teacher',
					})
					if (inviteResponse.ok) {
						if (!inviteResponse.result.isNew) {
							const { updateUser: state } = await updateContentUser({
								by: { personId: inviteResponse.result.person.id },
								data: {
									organization: { connect: { id: organizationId } }
								}
							})
							if (state.ok) {
								returnMessages.push(
									`Email ${trimmedEmail} je již registrován a byl přidán do školy`,
								)
							} else {
								returnMessages.push(`Email ${trimmedEmail} je již registrován v rámci jiné školy`)
							}
						} else {
							const personId = inviteResponse.result.person.id
							const identityId = inviteResponse.result.person.identity.id
							const { createUser: state } = await createUser({
								email: trimmedEmail,
								role: 'teamManager',
								personId: personId,
								identityId: identityId,
								organization: { connect: { id: organizationId } },
							})
							if (state.ok) {
								returnMessages.push(
									`Email ${trimmedEmail} byl úspěšně registrován`,
								)
							}
						}
					} else {
						returnMessages.push(`Email ${trimmedEmail} je již registrován`)
					}
				}
			}
			return returnMessages
		},
		[],
	)
}
