import * as React from 'react'
import { Button, Component, DataBindingProvider, EntitySubTree, FieldView, LinkButton, LogoutLink as LogoutLinkComponent, Menu, useIdentity, useLogout } from '@contember/admin'
import { FeedbackRendererNoSpinner } from './FeedBackRendererNoSpinner'

type NavigationProps = {
	layout: 'admin' | 'organization' | 'team'
}
export const Navigation = Component(({ layout }: NavigationProps) => {
	const identity = useIdentity()

	switch (layout) {
		case 'admin':
			return (
				<Menu>
					<Menu.Item title="Číselníky">
						<Menu.Item title="Ročníky" to="admin/edition/list" />
						<Menu.Item title="Typ docházky" to="admin/attendanceType/list" />
						<Menu.Item title="Kategorie týmu" to="admin/teamCategory/list" />
					</Menu.Item>
					<Menu.Item title="Správa">
						<Menu.Item title="Školy" to="admin/organization/list" />
						<Menu.Item title="Školy - tisk" to="admin/organization/listPrint" />
						<Menu.Item title="Třídy" to="admin/team/listClass" />
						<Menu.Item title="Doprovodné aktivity" to="admin/team/activitiesList" />
						<Menu.Item title="Správci" to="admin/user/list" />
						<Menu.Item title="Smazané třídy" to="admin/team/listClassArchive" />
						<Menu.Item title="Historická data" to="admin/organization/listHistoryArchive" />
					</Menu.Item>
					<Menu.Item title="Materiály">
						<Menu.Item title="Materiály ke stažení" to="admin/educationMaterial/list" />
						<Menu.Item
							title="Kategorie"
							to="admin/educationalMaterialCategory/list"
						/>
					</Menu.Item>
				</Menu>
			)
		case 'organization':
			return (
				<>
					<Menu>
						<Menu.Item title="Správa">
							<Menu.Item title="Dashboard" to="organization/dashboard" />
							<Menu.Item title="Výsledky" to="organization/list" />
							<Menu.Item title="Správci" to="organization/user/list" />
							<Menu.Item title="Hromadné zadání bodů" to="organization/bulkPointSubmission" />
							<Menu.Item title="Dopravodoná aktivita za školu" to="organization/accompanyingActivity" />
							<Menu.Item title="Materiály ke stažení" to="organization/materials" />
							<Menu.Item title="Nastavení" to="organization/settings" />
						</Menu.Item>
					</Menu>
				</>
			)
		case 'team':
			return (
				<>
					<DataBindingProvider stateComponent={FeedbackRendererNoSpinner}>
						<EntitySubTree entity={`User(personId='${identity.person?.id}')`}>
							<FieldView
								field={'organization.id'}
								render={({ value }) => (
									typeof value === 'string' ?
										<>
											<LinkButton elevation="none" to="team/dashboard" >Naše třída</LinkButton>
											<LinkButton elevation="none" to="team/bulkPointSubmission">Hromadné zadání bodů</LinkButton>
											<LinkButton elevation="none" to="team/manager" >Správce třídy</LinkButton>
											<LinkButton elevation="none" to="team/materials">Materiály ke stažení</LinkButton>
											<a href={`/view/organization?id=${value}`} className="cui-button view-justifyCenter elevation-none"><div className="cui-button-content">Moje škola</div></a>
											<a href="/view/help" className="cui-button view-justifyCenter elevation-none"><div className="cui-button-content">Nápověda</div></a>
										</> :
										<>
											<LinkButton elevation="none" to="team/dashboard" >Náš tým</LinkButton>
											<LinkButton elevation="none" to="team/manager" >Správce týmu</LinkButton>
											<LinkButton elevation="none" to="team/materials">Materiály ke stažení</LinkButton>
											<a href="/view/help" className="cui-button view-justifyCenter elevation-none"><div className="cui-button-content">Nápověda</div></a>
										</>
								)}
							/>
						</EntitySubTree>
					</DataBindingProvider>
					<LogoutLink />
				</>
			)
	}
})

export const LogoutLink = Component(() => (
	<LogoutLinkComponent>Odhlásit</LogoutLinkComponent>
))

export const LogoutButton = Component(() => {
	const onLogout = useLogout()
	const onClick = React.useRef(() => onLogout())
	onClick.current = onLogout

	return (
		<Button
			elevation="none"
			onClick={onClick.current}
		>Odhlásit</Button>
	)
})
