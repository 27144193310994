import * as React from 'react'
import { Button, ButtonProps, Component, Field, Icon, useField, usePersist, usePersistWithFeedback, useRedirect } from '@contember/admin'

type DeleteTeamProps = {
	children?: React.ReactNode
	redirectToOnSuccess?: string
	buttonProps?: ButtonProps
}

export const DeleteTeam = Component<DeleteTeamProps>(
	({ children, redirectToOnSuccess, buttonProps }) => {
		const isDeletedField = useField('isDeleted')
		const persist = usePersistWithFeedback()
		const redirect = useRedirect()

		return (
			<>
				<Button
					distinction="primary"
					intent="danger"
					onClick={async () => {
						if (confirm('Opravdu si přejete odstranit tým?')) {
							isDeletedField.updateValue(true)
							await persist()
							redirectToOnSuccess && redirect(redirectToOnSuccess)
						}
					}}
					{...buttonProps}>
					{children || <Icon blueprintIcon="trash" />}
				</Button>
			</>
		)
	},
	() => (<>
		<Field field="isDeleted" />
	</>),
	'DeleteTeam',
)
