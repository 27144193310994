import { Box, Component, EntityAccessor, EntityListSubTree, Field, HasMany, HasOne, If, NumberField, PersistButton, RadioField, SelectField, Stack, TextField, useEntity, useEntityListSubTree } from '@contember/admin'
import * as React from 'react'
import { willParticipateLabels } from '../../utils/labels'
import { ANALOG_PRICE } from '../../utils/constant'
import { DigitalNotice } from '../partials/howWillYouParticipate/DigitalNotice'
import { AnalogPrintSelfNotice } from '../partials/howWillYouParticipate/AnalogPrintSelfNotice'
import { AnalogByPostNotice } from '../partials/howWillYouParticipate/AnalogByPostNotice'

type NewEditionOrganizationQuestionnaireProps = {
}

export const NewEditionOrganizationQuestionnaire = Component<NewEditionOrganizationQuestionnaireProps>(({ }) => {
	const entity = useEntity()
	const editionAttendanceQuestionnaires = entity.getEntityList('editionAttendanceQuestionnaires')
	const currentEditions = useEntityListSubTree('currentEdition')
	let currentEdition: EntityAccessor | undefined = undefined

	if (Array.from(currentEditions).length > 0) {
		currentEdition = Array.from(currentEditions)[0]
	}

	React.useEffect(() => {
		if (currentEdition) {
			const questionnaires = Array.from(editionAttendanceQuestionnaires)
			const hasCurrentEditionQuestionnaire = questionnaires.find(it => it.getEntity('edition').idOnServer === currentEdition.idOnServer)
			if (!hasCurrentEditionQuestionnaire) {
				editionAttendanceQuestionnaires.createNewEntity(newEntity => {
					newEntity().connectEntityAtField('edition', currentEdition)
				})
			}
		}
	}, [currentEdition])

	const currentEditionQuestionnaire = Array.from(editionAttendanceQuestionnaires).find(it => it.getEntity('edition').idOnServer === currentEdition?.idOnServer)
	React.useEffect(() => {
		if (currentEditionQuestionnaire && currentEditionQuestionnaire.getField('howWillYouParticipate').value === 'analogByPost') {
			const addresses = entity.getEntityList('addresses')
			if (Array.from(addresses).length === 0) {
				addresses.createNewEntity(newEntity => {
					newEntity().updateValues({
						type: 'delivery',
					})
				})
				addresses.createNewEntity(newEntity => {
					newEntity().updateValues({
						type: 'billing',
					})
				})
			}
		} else {
			const addresses = entity.getEntityList('addresses')
			Array.from(addresses).forEach(it => !it.existsOnServer && it.deleteEntity())

		}
	}, [editionAttendanceQuestionnaires])

	if (!currentEdition) {
		return null
	}

	return (
		<>
			<Stack direction="vertical">
				<HasMany field="editionAttendanceQuestionnaires">
					<If condition={`[edition.id='${currentEdition.idOnServer}']`}>
						<h3 className="text-base font-semibold leading-6 text-gray-900">Dotazník k účasti v novém ročníku</h3>
						<Stack direction="vertical">
							<RadioField
								field="willParticipate"
								label="Budete se účastnit aktuálního ročníku"
								options={willParticipateLabels}
								required
							/>
							<If condition="[willParticipate=true]">
								<RadioField
									field="howWillYouParticipate"
									label="Jakým způsobem chcete zaznamenávat cesty do školy?"
									options={[
										{
											value: 'digital',
											label: 'Digitálně v aplikaci',
										},
										{
											value: 'analogPrintSelf',
											label: <div className="flex gap-2"><span>Na papír (vystiskneme si sami)</span></div>,
										},
										{
											value: 'analogByPost',
											label: <div className="flex flex-wrap gap-2 items-center">
												<span>Objednat originální papírovou motivační sadu (objednat do 15. 6.)</span>
												<span className="inline-flex bg-red-500 text-white text-[12px] rounded-md px-2 py-1">Zploplatněno {ANALOG_PRICE},-</span>
											</div>,
										},
									]}
									defaultValue={true}
								/>
								<If condition="[howWillYouParticipate=digital]">
									<DigitalNotice />
								</If>
								<If condition="[howWillYouParticipate=analogPrintSelf]">
									<AnalogPrintSelfNotice />
								</If>
								<If condition="[howWillYouParticipate=analogByPost]">
									<AnalogByPostNotice />
								</If>
							</If>
						</Stack>
					</If>
				</HasMany>
				{currentEditionQuestionnaire && <div className="max-w-40"><NumberField field="teamCounts" label="Kolik tříd se zúčastní?" /></div>}
				{currentEditionQuestionnaire && currentEditionQuestionnaire.getField<string>('howWillYouParticipate').value === 'analogByPost' && <HasMany field="addresses">
					<div className="my-6">
						<If condition="[type=delivery]">
							<Box distinction="seamless" heading="Doručovací údaje">
								<TextField field="name" label="Jméno školy" />
								<TextField field="idNo" label="IČ" />
								<TextField field="street" label="Ulice a č.p." />
								<TextField field="city" label="Město" />
								<TextField field="postalCode" label="PSČ" />
							</Box>
						</If>
						<If condition="[type=billing]">
							<Box distinction="seamless" heading="Fakturační údaje">
								<TextField field="name" label="Jméno školy" />
								<TextField field="idNo" label="IČ" />
								<TextField field="street" label="Ulice a č.p." />
								<TextField field="city" label="Město" />
								<TextField field="postalCode" label="PSČ" />
							</Box>
						</If>
					</div>
				</HasMany>}
			</Stack>
			<Stack justify="center" direction="horizontal" className="mt-4">
				<PersistButton />
			</Stack>
		</>
	)
}, () => (<>
	<EntityListSubTree
		entities={`Edition`}
		alias="currentEdition"
		orderBy="startedAt desc"
	/>
	<HasMany field="editionAttendanceQuestionnaires">
		<Field field="willParticipate" defaultValue={true} />
		<Field field="howWillYouParticipate" />
		<Field field="teamCounts" />
		<HasOne field="edition" />
	</HasMany>
	<Field field="teamCounts" />
	<HasMany field="addresses">
		<div className="flex gap-2 flex-col">
			<Field field="type" />
			<Field field="name" />
			<Field field="idNo" />
			<Field field="street" />
			<Field field="city" />
			<Field field="postalCode" />
		</div>
	</HasMany>
</>), 'NewEditionOrganizationQuestionnaire')
