import {
	Button,
	Component,
	EntityListSubTree,
	Field,
	FieldView,
	HasMany,
	HasOne,
	Stack,
	useEntity,
	useEntityListSubTree,
} from '@contember/admin'
import { addBusinessDays, addDays, format, formatISO, isAfter, isSameDay, parse, parseISO, startOfWeek } from 'date-fns'
import { cs } from 'date-fns/locale'
import * as React from 'react'
import { useGetCurrentEdition } from '../../hooks/useGetCurrentEdition'
import { AttendanceFormTeamMember } from './AttendanceFormTeamMember'
import { decimalFormat } from '../../utils/format'

type AttendanceFormProps = {
	formType: 'simplified' | 'complex'
	setFormType: React.Dispatch<React.SetStateAction<'simplified' | 'complex'>>
	attendanceNonCompetitive: boolean
	setAttendanceNonCompetitive: React.Dispatch<React.SetStateAction<boolean>>
}

export const AttendanceForm = Component<AttendanceFormProps>(
	({ formType, setFormType, attendanceNonCompetitive, setAttendanceNonCompetitive }) => {
		const entity = useEntity()
		const editions = useEntityListSubTree('editions')
		const currentEdition = useGetCurrentEdition(editions)
		const currentEditionStartedAt = currentEdition.getField<string>('startedAt').value
		const currentEditionFinishedAt = currentEdition.getField<string>('finishedAt').value

		const specialCompetitionWeek = entity.getField<string>('specialCompetitionWeek').value

		const urlParams = new URLSearchParams(window.location.search)
		const dateParam = urlParams.get('date')
		const attendanceTypes = useEntityListSubTree('attendanceTypes')
		const dateFormat = 'y-MM-dd'


		let currentDate = new Date()
		let date = new Date()
		if (dateParam) {
			date = parse(dateParam, dateFormat, new Date())
			if (formType === 'simplified') {
				currentDate = date
			}
		} else if (attendanceNonCompetitive) {
			date = new Date()
		} else if (specialCompetitionWeek) {
			date = parseISO(specialCompetitionWeek)
		} else if (currentEditionStartedAt) {
			date = parseISO(currentEditionStartedAt)
		}

		const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })
		let datesOfCurrentWeek = [
			startOfWeekDate,
			addDays(startOfWeekDate, 1),
			addDays(startOfWeekDate, 2),
			addDays(startOfWeekDate, 3),
			addDays(startOfWeekDate, 4),
		]

		const czechNationalHolidays = [
			'2023-01-01', // New Year's Day
			'2023-03-28', // Easter Monday
			'2023-05-01', // Labour Day
			'2023-05-08', // Liberation Day
			'2023-07-05', // Saints Cyril and Methodius Day
			'2023-07-06', // Jan Hus Day
			'2023-09-28', // Czech Statehood Day
			'2023-10-28', // Independent Czechoslovak State Day
			'2023-11-17', // Struggle for Freedom and Democracy Day
			'2023-12-24', // Christmas Eve
			'2023-12-25', // Christmas Day
			'2023-12-26', // St. Stephen's Day
		]

		if (specialCompetitionWeek) {
			const specialDate = parseISO(specialCompetitionWeek)
			let dayPointer = 0
			datesOfCurrentWeek = []

			while (datesOfCurrentWeek.length < 5) {
				let potentialDate = addBusinessDays(specialDate, dayPointer)

				// Check if the date is not a Czech national holiday
				if (!czechNationalHolidays.includes(format(potentialDate, dateFormat))) {
					datesOfCurrentWeek.push(potentialDate)
				}

				dayPointer += 1
			}
		}


		if (Array.from(entity.getEntityList('bulkPointSubmissions')).length > 0) {
			return (<div className="bg-white p-10 rounded text-center text-black font-bold">Tým má vyplněnou docházku hromadně.</div>)
		}


		// nesoutěžní zadávání docházky
		if (attendanceNonCompetitive && currentEditionFinishedAt && isAfter(new Date(), parseISO(currentEditionFinishedAt))) {
			const currentNonCompetitiveDate = startOfWeek(new Date(), { weekStartsOn: 1 })
			datesOfCurrentWeek = [
				currentNonCompetitiveDate,
				addDays(currentNonCompetitiveDate, 1),
				addDays(currentNonCompetitiveDate, 2),
				addDays(currentNonCompetitiveDate, 3),
				addDays(currentNonCompetitiveDate, 4),
			]

			return <>
				<NonCompetitiveSwitcher
					currentEditionFinishedAt={currentEditionFinishedAt}
					attendanceNonCompetitive={attendanceNonCompetitive}
					setAttendanceNonCompetitive={setAttendanceNonCompetitive}
				/>
				<p><button onClick={() => setFormType('simplified')} className="cui-button view-justifyCenter elevation-none"><div className="cui-button-content">Přepnout na zjednodušené zadávání</div></button></p>
				<table className="table">
					<thead>
						<tr>
							<th className="name-cell">Jméno</th>
							<th className="date-cell">
								{format(datesOfCurrentWeek[0], 'd. M. (EEEE)', { locale: cs })}
							</th>
							<th className="date-cell">
								{format(datesOfCurrentWeek[1], 'd. M. (EEEE)', { locale: cs })}
							</th>
							<th className="date-cell">
								{format(datesOfCurrentWeek[2], 'd. M. (EEEE)', { locale: cs })}
							</th>
							<th className="date-cell">
								{format(datesOfCurrentWeek[3], 'd. M. (EEEE)', { locale: cs })}
							</th>
							<th className="date-cell">
								{format(datesOfCurrentWeek[4], 'd. M. (EEEE)', { locale: cs })}
							</th>
						</tr>
					</thead>
					<tbody>
						<HasMany field="teamMembers" orderBy="order asc">
							<AttendanceFormTeamMember
								datesOfCurrentWeek={datesOfCurrentWeek}
								attendanceTypes={attendanceTypes}
								dateFormat={dateFormat}
								attendanceNonCompetitive={true}
							/>
						</HasMany>
					</tbody>
				</table>
			</>
		}


		// denní zadávání docházky
		if (formType === 'simplified') {
			if (!datesOfCurrentWeek.some(it => isSameDay(it, currentDate))) {
				currentDate = datesOfCurrentWeek[0]
			}
			return (
				<>
					<NonCompetitiveSwitcher
						currentEditionFinishedAt={currentEditionFinishedAt}
						attendanceNonCompetitive={attendanceNonCompetitive}
						setAttendanceNonCompetitive={setAttendanceNonCompetitive}
					/>
					<div className="flex justify-center">
						<Stack direction="horizontal" gap="small">
							{datesOfCurrentWeek.map(dayOfCurrentWeek => {
								return <button
									key={formatISO(dayOfCurrentWeek)}
									onClick={() => window.location.href = `${window.location.pathname}?id=${entity.idOnServer}&date=${format(dayOfCurrentWeek, dateFormat)}`}
									className="cui-button text-xl view-justifyCenter elevation-none disabled:bg-slate-100 !opacity-100 disabled:border-2 disabled:border-blue-400"
									disabled={isSameDay(dayOfCurrentWeek, currentDate)}>
									<div className="cui-button-content capitalize">{format(dayOfCurrentWeek, 'EEEE', { locale: cs })}</div>
								</button>
							})}
						</Stack>
					</div>

					{
						datesOfCurrentWeek.some(it => isSameDay(it, currentDate)) ? (<table className="table table-attendance">
							<thead>
								<tr>
									<th className="name-cell text-left">Jméno</th>
									<th className="date-cell">
										{format(currentDate, 'd. M. (EEEE)', { locale: cs })}
									</th>
									<th className="total-points-cell">Celkem bodů</th>
								</tr>
							</thead>
							<tbody>
								<HasMany field="teamMembers" orderBy="order asc">
									<AttendanceFormTeamMember
										datesOfCurrentWeek={[currentDate]}
										attendanceTypes={attendanceTypes}
										dateFormat={dateFormat}
										fillType="pictogram"
									/>
								</HasMany>
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={3}>
										<p>
											Součet bodů za celou třídu (přepočtený na “ideální třídu”):&nbsp;
											<strong>
												<HasOne field="stats">
													<FieldView<number> field="totalStandardizedPoints" render={({ value }) => value ? decimalFormat(value) : 0} />
												</HasOne> bodů
											</strong></p>
									</td>
								</tr>
							</tfoot>
						</table>) : (<p>Pro dnešní den nelze zadávat docházku</p>)
					}

					<div className="flex justify-end"><button onClick={() => setFormType('complex')} className="cui-button view-justifyCenter elevation-none"><div className="cui-button-content">Přepnout na zadávání celého týdne najednou</div></button></div>

				</>
			)
			// týdenní zadávání docházky
		} else {
			return (
				<>
					<NonCompetitiveSwitcher
						currentEditionFinishedAt={currentEditionFinishedAt}
						attendanceNonCompetitive={attendanceNonCompetitive}
						setAttendanceNonCompetitive={setAttendanceNonCompetitive}
					/>
					<p><button onClick={() => setFormType('simplified')} className="cui-button view-justifyCenter elevation-none"><div className="cui-button-content">Přepnout na zjednodušené zadávání</div></button></p>
					<table className="table">
						<thead>
							<tr>
								<th className="name-cell">Jméno</th>
								<th className="date-cell">
									{format(datesOfCurrentWeek[0], 'd. M. (EEEE)', { locale: cs })}
								</th>
								<th className="date-cell">
									{format(datesOfCurrentWeek[1], 'd. M. (EEEE)', { locale: cs })}
								</th>
								<th className="date-cell">
									{format(datesOfCurrentWeek[2], 'd. M. (EEEE)', { locale: cs })}
								</th>
								<th className="date-cell">
									{format(datesOfCurrentWeek[3], 'd. M. (EEEE)', { locale: cs })}
								</th>
								<th className="date-cell">
									{format(datesOfCurrentWeek[4], 'd. M. (EEEE)', { locale: cs })}
								</th>
								<th className="total-points-cell">Celkem bodů</th>
							</tr>
						</thead>
						<tbody>
							<HasMany field="teamMembers" orderBy="order asc">
								<AttendanceFormTeamMember
									datesOfCurrentWeek={datesOfCurrentWeek}
									attendanceTypes={attendanceTypes}
									dateFormat={dateFormat}
								/>
							</HasMany>
						</tbody>
						<tfoot>
							<tr>
								<td colSpan={7}>
									<p>
										Součet bodů za celou třídu (přepočtený na “ideální třídu”):&nbsp;
										<strong>
											<HasOne field="stats">
												<FieldView<number> field="totalStandardizedPoints" render={({ value }) => value ? decimalFormat(value) : 0} />
											</HasOne> bodů
										</strong></p>
								</td>
							</tr>
						</tfoot>
					</table>
				</>
			)
		}
	},
	() => (
		<>
			<Field field="specialCompetitionWeek" />
			<HasMany field="teamMembers" orderBy="order asc">
				<Field field="name" />
				<Field field="order" />
				<HasMany field="attendances">
					<Field field="date" />
					<Field field="wasPresent" />
					<HasOne field="attendanceType" />
					<Field field="createdAt" />
					<Field field="isCompetitive" />
				</HasMany>
				<HasOne field="stats">
					<Field field="totalPoints" />
				</HasOne>
			</HasMany>
			<HasOne field="stats">
				<Field field="totalStandardizedPoints" />
			</HasOne>
			<HasMany field="bulkPointSubmissions" />
			<EntityListSubTree entities="AttendanceType" alias="attendanceTypes">
				<Field field="name" />
				<HasOne field="icon">
					<Field field="url" />
					<Field field="width" />
					<Field field="height" />
					<Field field="alt" />
				</HasOne>
				<Field field="points" />
			</EntityListSubTree>
			<EntityListSubTree
				entities="Edition"
				alias="editions"
				orderBy="startedAt desc"
			>
				<Field field="startedAt" />
				<Field field="finishedAt" />
				<Field field="isOpen" />
			</EntityListSubTree>
		</>
	),
	'AttendanceForm',
)

type NonCompetitiveSwitcherProps = {
	attendanceNonCompetitive: boolean
	setAttendanceNonCompetitive: React.Dispatch<React.SetStateAction<boolean>>
	currentEditionFinishedAt: string | null

}

const NonCompetitiveSwitcher = Component<NonCompetitiveSwitcherProps>(({ attendanceNonCompetitive, setAttendanceNonCompetitive, currentEditionFinishedAt }) => {
	if (!currentEditionFinishedAt) {
		return null
	}

	if (attendanceNonCompetitive) {
		return (
			<div className="bg-red-100 p-10 py-3 rounded text-center text-black font-bold">
				Zadáváte docházku v nesoutěžním režimu.
				<Button onClick={() => setAttendanceNonCompetitive(false)} distinction="primary" className="ml-3">Zpět na soutěžní zadávání</Button>
			</div>
		)
	} else if (isAfter(new Date(), parseISO(currentEditionFinishedAt))) {
		return (
			<div className="bg-green-100 p-10 py-3 rounded text-center text-black font-bold">
				Chcete pokračovat v zadávání docházky i po skončení soutěže?
				<Button onClick={() => setAttendanceNonCompetitive(true)} distinction="primary" className="ml-3">Můžete zadávat docházku v nesoutěžním režimu.</Button>
			</div>
		)

	}
	return null

})
