import * as React from 'react'
import { DataGridPage, HasOneSelectCell, NumberCell, TextCell, useIdentity } from '@contember/admin'
import { decimalFormat } from '../../utils/format'
import { CURRENT_VINTAGE_CREATED_AFTER } from '../../utils/constant'

export default () => {
	const identity = useIdentity()
	return (
		<>
			<DataGridPage
				entities={`Team[organization.users.personId='${identity.person?.id}' && createdAt > '${CURRENT_VINTAGE_CREATED_AFTER}']`}
				itemsPerPage={50}
				rendererProps={{ title: 'Výsledky' }}
			>
				<TextCell field="name" header="Název třídy" />
				<HasOneSelectCell field="category" header="Kategorie týmu" options="TeamCategory.name" />
				<NumberCell field="stats.totalStandardizedPoints" header="Body za docházku" format={decimalFormat} />
				<NumberCell field="stats.accompanyingActivities" header="Body za doprovodnou aktivitu" format={decimalFormat} />
				<NumberCell field="stats.totalStandardizedPointsWithAccompanyingActivities" initialOrder="desc" header="Celkem bodů" format={decimalFormat} />
			</DataGridPage>
		</>
	)
}
