import * as React from 'react'
import { DataGridPage, EnumCell, GenericCell, Icon, Link, LinkButton, TextCell } from '@contember/admin'
import { userRoles } from '../../../modules/userRoles'

export default () => (
	<>
		<DataGridPage
			entities="User"
			itemsPerPage={50}
			rendererProps={{
				title: 'Správci',
				actions: (
					<>
						<LinkButton to="organization/user/invite">Hromadná pozvánka</LinkButton>
						<LinkButton to="organization/user/create">Pozvat správce</LinkButton>
					</>
				),
			}}
		>
			<GenericCell shrunk canBeHidden={false}>
				<Link to="organization/user/edit(id: $entity.id)">
					<Icon blueprintIcon="edit" />
				</Link>
			</GenericCell>
			<TextCell field="lastName" header="Příjmení" />
			<TextCell field="firstName" header="Jméno" />
			<TextCell key="email" field="email" header="E-mail" />
			<EnumCell
				field="role"
				header="Role"
				options={Object.fromEntries(userRoles.map(role => [role.value, role.label]))}
			/>
		</DataGridPage>
	</>
)
