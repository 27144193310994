import { Box, CheckboxField, DateTimeField, Field, FloatField, HasMany, HasOne, Heading, If, Link, MultiEditPage, NumberField, Repeater, SelectField, Stack, StaticRender, TextField } from '@contember/admin'
import * as React from 'react'
import { Collapsible } from '../../../components/Collapsible'
import { regions } from '../../../modules/regions'
import { EditionSummaryPrepare } from '../../../components/buttons/EditionSummaryPrepare'

export default () => {
	return (
		<MultiEditPage
			entities="Edition"
			rendererProps={{ title: 'Ročníky', sortableBy: 'displayOrder' }}
		>
			<Stack
				direction="vertical"
				onResize={undefined}
				onResizeCapture={undefined}
			>
				<TextField field="name" label="Název" />
				<CheckboxField
					field="isOpen"
					label="Registrace otevřena"
					defaultValue={false}
				/>
				<Stack
					direction="horizontal"
					align="center"
					justify="stretch"
					onResize={undefined}
					onResizeCapture={undefined}
				>
					<DateTimeField field="startedAt" label="Ročník začíná" />
					<DateTimeField field="finishedAt" label="Ročník končí" />
				</Stack>
				<Collapsible collapseText="Skrýt" expandText="Zobrazit vyhodnocení ročníku">
					<StaticRender>
						<EditionSummaryPrepare />
					</StaticRender>
					<Box
						heading={<Stack direction="horizontal" align="center" className="mt-5 mb-5">
							<Heading depth={5}>Vyhodnocení ročníku</Heading>
							<EditionSummaryPrepare />
						</Stack>}
						distinction="seamless"
					>
						<Stack direction="vertical" gap="large">
							<Repeater field="regionSumUps" label={undefined} orderBy={undefined} initialEntityCount={0}>
								<SelectField field="region" options={regions} label="Kraj" />
								<Stack direction="horizontal" evenly>
									<NumberField field="schoolCount" label="Počet škol" />
									<NumberField field="classCount" label="Počet tříd" />
									<FloatField field="totalPoints" label="Počet bodů" />
								</Stack>
							</Repeater>
							<CheckboxField
								field="areResultsPublic"
								label="Zveřejnit výsledky"
								defaultValue={false}
							/>
							<If condition="[areResultsPublic=true]">
								<Link to="view/results(edition: $entity.id)">Zobrazit výsledky</Link>
							</If>
						</Stack>
					</Box>
				</Collapsible>
			</Stack>
		</MultiEditPage>
	)
}
